img {
  border-radius: 50%;
  height: 45px;
  margin-top: -20px;
  border: 2px solid black;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 25px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  overflow-wrap: break-word;
}

.msg {
  display: flex;
  padding: 20px 10px 0 20px;
  margin: 20px;
  border-radius: 3000px;
  box-shadow: 0 0 10px rgb(164, 164, 164);
  align-items: center;
}

.sent {
  background-color: #395dff;
  color: white;
  border-top-right-radius: 1000px;
  flex-direction: row-reverse;
  padding: 20px 20px 0 10px;
  text-align: end;
  float: right;
}

.received {
  border: 1px solid lightgray;
  background-color: #FAFAFA;
  border-top-left-radius: 1000px;
  float: left;
}

.sendMsg {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid lightgray;
  margin-left: -5px;
  padding: 10px;
  padding-bottom: 30px;
  background-color: #fafafa;
}

.msgs {
  margin: 110px 0;
  display: flex;
  flex-direction: column;
}


@media (max-width: 775px) {
  p {
    font-size: 20px;
  }
  .sent {
    padding: 10px 10px 0 10px;
  }
  .received {
    padding: 10px 10px 0 10px;
  }
  img {
    height: 35px;
    margin-top: -10px;
  }
}

@media (max-width: 500px) {
  p {
    font-size: 15px;
  }
  .sent {
    padding: 7px 7px 0 7px;
  }
  .received {
    padding: 7px 7px 0 7px;
  }
  img {
    height: 30px;
    margin-top: -7px;
  }
}


